<div class="flex justify-center bg-page">
  <!-- Main container -->
  <div class="overflow-hidden bg-white max-screen">
    <!-- Presentation image -->
    <div class="flex items-center justify-center">
      <img
        src="./../assets/imgs/presentation.png"
        class="md:w-full lg:max-w-full-2xl"
      />
    </div>

    <!-- Header  -->
    <div class="flex items-center justify-center">
      <div class="px-2">
        <p class="text-center mt-5 font-gistesy text-3xl lg:text-7xl">
          Tantas vidas, tantos siglos, tantos mundos <br />
          y coincidir
        </p>
      </div>
    </div>

    <!-- Names logo -->
    <div class="relative h-fit w-full">
      <div class="flex items-center justify-center w-full">
        <img src="./../assets/imgs/names-logo.png" class="w-4/6 md:w-1/3" />
      </div>
      <div>
        <p class="font-dancing text-3xl text-center mt-2 md:text-6xl">
          Los esperamos para celebrar <br />
          juntos el día
        </p>
      </div>
      <div class="absolute -right-28 bottom-10 w-1/2 md:-right-80">
        <img src="./../assets/imgs/stain.png" />
      </div>
    </div>
    <!-- Date -->
    <div class="px-8 flex items-center justify-center">
      <img src="./../assets/imgs/date.png" class="md:w-1/2" />
    </div>

    <!-- Fathers -->
    <div class="flex items-center justify-center">
      <div class="px-2">
        <p class="text-center mt-5 font-dancing text-3xl lg:text-7xl">
          Karen Jazmin Tirado Nevarez
        </p>
        <p
          class="text-center mt-5 font-quicksand text-3xl lg:text-6xl font-bold"
        >
          Padres
        </p>
        <p class="text-center mt-5 font-quicksand lg:text-6xl text-21p">
          Ma. del Carmen Nevarez Guadiana
        </p>
        <p
          class="text-center mt-2 font-quicksand text-22p lg:text-6xl md:mt-4 md:mb-1"
        >
          Domingo Tirado Aguirre
        </p>
      </div>
    </div>
    <!-- hands -->
    <div class="relative h-fit w-full">
      <div class="flex items-center justify-center">
        <img src="./../assets/imgs/hands.png" class="w-3/5 md:w-2/5" />
      </div>
      <div class="absolute -top-7 md:-top-8">
        <img src="./../assets/imgs/decoration.png" class="h-56 md:h-128" />
      </div>
    </div>
    <!-- Fathers -->
    <div class="flex items-center justify-center">
      <div class="px-2">
        <p class="text-center mt-5 font-dancing text-3xl lg:text-7xl">
          Jesús Adrián García Mendoza
        </p>
        <p
          class="text-center mt-5 font-quicksand text-3xl lg:text-6xl font-bold"
        >
          Padres
        </p>
        <p class="text-center mt-5 font-quicksand text-21p lg:text-6xl">
          Bertha Mendoza Osuna
          <i class="fa fa-cross"> </i>
        </p>
        <p
          class="text-center mt-2 font-quicksand text-22p lg:text-6xl md:mt-4 md:mb-1"
        >
          Jesús Simón García Castro
        </p>
      </div>
    </div>
    <!-- P -->
    <div class="flex items-center justify-center">
      <div class="px-2">
        <p class="text-center mt-5 font-dancing text-3xl lg:text-7xl">
          Padrinos de Matrimonio
        </p>
        <p class="text-center mt-5 font-quicksand text-22p lg:text-6xl">
          Francisco Pardo Meza
        </p>
        <p
          class="text-center mt-2 font-quicksand text-22p lg:text-6xl md:mt-4 md:mb-1"
        >
          Rosa María García Castro
        </p>
      </div>
    </div>

    <!-- Copas -->
    <div class="relative h-fit w-full my-10">
      <div class="flex items-center justify-center">
        <img src="./../assets/imgs/copas.png" class="w-2/6 md:w-1/5" />
      </div>
    </div>

    <!-- presidio -->

    <div class="text-center">
      <h1 class="text-3xl font-quicksand font-bold">PRESIDIO</h1>
      <p class="font-quicksand text-1xl font-bold">
        Blvd. Niños Héroes 1511, <br />
        Centro, 82000 Mazatlán,
      </p>
    </div>

    <!-- anillo -->
    <div class="flex items-center justify-center mt-10">
      <img src="./../assets/imgs/anillo.png" class="w-4/5 md:w-1/3" />
    </div>

    <!-- generate row with two columns with text center -->
    <div class="grid grid-cols-2 gap-2">
      <div class="text-center">
        <p class="text-center font-dancing text-3xl lg:text-7xl">Misa</p>
        <p class="text-center font-quicksand text-22p lg:text-6xl">5 PM</p>
        <p class="text-center font-quicksand text-22p lg:text-6xl">
          San Carlos Borromeo
        </p>
      </div>
      <div class="">
        <p class="text-center font-dancing text-3xl lg:text-7xl">Recepción</p>
        <p class="text-center font-quicksand text-22p lg:text-6xl">8 PM</p>
        <p class="text-center font-quicksand text-22p lg:text-6xl">Presidio</p>
      </div>
    </div>

    <div class="text-center font-quicksand text-2xl font-bold mt-5">
      ¿Como llegar?
    </div>
    <div class="grid grid-cols-2 gap-2 justify-items-center mt-2">
      <div class="buttons-maps">
        <a
          type="button"
          href="https://maps.app.goo.gl/NEAD8Aq3f7sKp7NY6"
          target="_blank"
          >Mapa</a
        >
      </div>
      <div class="buttons-maps">
        <a
          type="button"
          href="https://maps.app.goo.gl/vUqAEhum4eA8wJQv6"
          target="_blank"
          >Mapa</a
        >
      </div>
    </div>

    <div class="w-full bg-gold mt-11 p-4">
      <div class="text-center">
        <p class="font-dancing text-6xl text-white">No niños</p>
      </div>
      <div class="flex justify-center mt-1">
        <img src="./../assets/imgs/ninos.png" class="w-1/3 md:w-3/12 lg:1/5" />
      </div>
      <div class="text-center">
        <p class="font-quicksand font-bold mt-1 text-2xl text-white">
          Amamos a tus niños <br />
          pero hemos reservado <br />
          el sitio sólo para adultos
        </p>
      </div>
      <div class="text-center">
        <p class="font-dancing text-4xl mt-2 text-white">
          ¡Niños dulce sueños!
        </p>
      </div>
    </div>

    <div class="w-full">
      <div class="grid grid-cols-2">
        <div class="w-full">
          <img src="./../assets/imgs/kya2.png" alt="" />
        </div>
        <div class="w-full">
          <img src="./../assets/imgs/kya1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="relative h-fit w-full mt-8">
      <div class="w-full justify-center text-center flex">
        <img
          src="./../assets/imgs/liemtime.png"
          class="w-4/5 md:w-3/6 lg:w-2/5"
        />
      </div>
      <div class="absolute -right-28 top-10 w-1/2 md:-right-80">
        <img src="./../assets/imgs/stain.png" />
      </div>
    </div>

    <div class="relative h-fit w-full mt-8">
      <div class="w-full text-center">
        <p class="font-dancing font-bold text-6xl">Regalos</p>
        <p class="mt-2 font-quicksand text-xl">
          Nuestro regalo más importante es tu <br />
          asistencia a nuestro día tan especial. <br />
          Si deseas darnos un obsequio, <br />éste lo puedes hacer en efectivo
          <br />
          colocándolo en sobres que podrás <br />
          depositar en el cofre que encontrarás <br />en la fiesta. <br />
          Muchas gracias.
        </p>
      </div>
      <div class="absolute -left-28 top-10 w-1/2 md:-left-80">
        <img src="./../assets/imgs/stain.png" />
      </div>
    </div>

    <div class="relative h-fit w-full mt-8 pt-12">
      <div class="w-full text-center">
        <p class="font-quicksand font-bold text-lg">
          ES MUY IMPORTANTE PARA NOSOTROS <br />
          QUE CONFIRMES TU ASISTENCIA
        </p>
      </div>

      <div
        class="flex justify-center w-full"
        *ngIf="
          (showForm | async) === false &&
          (showError | async) === false &&
          (showRequest | async) === false
        "
      >
        <div class="button-confirmation w-3/5" (click)="showForm.next(true)">
          <a>CONFIRME AQUI</a>
        </div>
      </div>

      <div
        class="flex justify-center w-full"
        *ngIf="(showForm | async) === true"
      >
        <input
          id="code"
          type="text"
          class="input-code mt-4"
          placeholder="Código de invitación"
          [(ngModel)]="invitationCode"
        />
      </div>
      <div
        class="flex justify-center w-full"
        *ngIf="(showError | async) === true"
      >
        <br />
        <label for="code" class="error-message"
          >Código de invitación invalido</label
        >
      </div>
      <div
        class="flex justify-center w-full mt-2 gap-5"
        *ngIf="(showForm | async) === true"
      >
        <div class="buttons-maps" (click)="showForm.next(false)">
          <a>Cancelar</a>
        </div>
        <div class="buttons-maps" (click)="sendRequestInvitation()">
          <a>Enviar</a>
        </div>
      </div>

      <div class="" *ngIf="(showRequest | async) === true">
        <div *ngIf="eventDay.confirmed === 1">
          <p class="text-center font-quicksand px-3">
            Ya has confirmado tu asistencia, si no podrás asistir por favor
            avisar a alguno de los novios para que este enterado.
          </p>
          <div class="flex justify-center text-center">
            <div class="button-confirmation w-3/5" (click)="downloadImage()">
              <a>Descargar invitación</a>
            </div>
          </div>
        </div>
        <div *ngIf="eventDay.confirmed === 2">
          <p class="text-center font-quicksand px-3">
            Has cancelado tu invitation, si quieres asistir al nuestro evento
            por favor contacta a alguno de los novios.
          </p>
        </div>
        <div *ngIf="eventDay.confirmed === 0">
          <!-- Buttons accept or reject invitation -->
          <div class="flex justify-center w-full mt-2 gap-5">
            <div class="buttons-maps" (click)="invitationAccept()">
              <a>Aceptar</a>
            </div>
            <div class="buttons-maps" (click)="invitationReject()">
              <a>Rechazar</a>
            </div>
          </div>
        </div>

        <div class="absolute -right-2 top-1 w-10 md:-right-80">
          <img src="./../assets/imgs/decoration.png" class="img-vertical" />
        </div>
      </div>

      <div class="w-full text-center mt-8">
        <p class="font-dancing text-6xl font-bold">Falta Poco!!</p>
      </div>
      <section class="mt-8">
        <div id="countDown" class="count-down-container"></div>
      </section>

      <div class="w-full text-center mt-12 mb-3 flex justify-center">
        <img src="./../assets/imgs/tks.png" class="w-full md:w-3/5 lg:w-2/5" />
      </div>
    </div>


    <div class="gallery mt-11 mb-20" *ngIf="!isMobile">
      <img src="./../assets/imgs/1.jpg" alt="" />
      <img src="./../assets/imgs/2.jpg" alt="" />
      <img src="./../assets/imgs/3.jpg" alt="" />
    </div>

    <!--  -->
    <div class="gallery-ver mt-11 mb-20" *ngIf="isMobile">
      <img src="./../assets/imgs/1.jpg" alt="" />
      <img src="./../assets/imgs/2.jpg" alt="" />
      <img src="./../assets/imgs/3.jpg" alt="" />
    </div>

    <!-- end -->
  </div>
</div>

<div
  class="fixed inset-0 z-10 flex items-center justify-center"
  *ngIf="(showModal | async) === true"
>
  <!-- Fondo oscuro para el diálogo -->
  <div class="fixed inset-0 z-10 bg-black opacity-50"></div>

  <!-- Contenedor del diálogo -->
  <div class="bg-white z-20 p-4 shadow-md rounded-md">
    <!-- Contenido del diálogo -->
    <p class="text-lg font-semibold mb-2">
      {{
        (validInvitation | async) === true
          ? "Invitación valida"
          : "Invitación invalida"
      }}
    </p>
    <p *ngIf="(validInvitation | async) === true">
      {{ eventDayValidate.name }} {{ eventDayValidate.lastname }}
    </p>
    <p *ngIf="(validInvitation | async) === true && eventDayValidate.table">
      Mesa: {{ eventDayValidate.table }}
    </p>
    <p *ngIf="(validInvitation | async) === true">
      Accesos: {{ eventDayValidate.access }}
    </p>
    <!-- Botón para cerrar el diálogo -->
    <div class="mt-2 justify-center" (click)="showModal.next(false)">
      <a class="button-confirmation">Cerrar</a>
    </div>
  </div>
</div>
