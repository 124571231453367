export function animationjs() {
  let countDown = document.getElementById("countDown");
}


function isElementInViewport(element) {
  var rect = element.getBoundingClientRect();
  var windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  // Verificar si el elemento está en la parte superior de la ventana o si se superpone con ella
  var isTopVisible = rect.top >= 0 && rect.top <= windowHeight;

  // Verificar si el elemento está en la parte inferior de la ventana o si se superpone con ella
  var isBottomVisible = rect.bottom >= 0 && rect.bottom <= windowHeight;

  // Retornar true si alguna parte del elemento está visible en la ventana
  return isTopVisible || isBottomVisible;
}

export function countDown() {
  simplyCountdown("#countDown", {
    year: 2024, // required
    month: 3, // required
    day: 15, // required
    hours: 17, // Default is 0 [0-23] integer
    minutes: 0, // Default is 0 [0-59] integer
    seconds: 0, // Default is 0 [0-59] integer
    words: {
      //words displayed into the countdown
      days: { singular: "dia", plural: "días" },
      hours: { singular: "hora", plural: "horas" },
      minutes: { singular: "minutos", plural: "minutos" },
      seconds: { singular: "segundo", plural: "segundos" },
    },
    plural: true, //use plurals
    inline: false, //set to true to get an inline basic countdown like : 24 days, 4 hours, 2 minutes, 5 seconds
    inlineClass: "simply-countdown-inline", //inline css span class in case of inline = true
    // in case of inline set to false
    enableUtc: true, //Use UTC or not - default : false
    onEnd: function () {
      return;
    }, //Callback on countdown end, put your own function here
    refresh: 1000, // default refresh every 1s
    sectionClass: "simply-section", //section css class
    amountClass: "simply-amount", // amount css class
    wordClass: "simply-word", // word css class
    zeroPad: false,
    countUp: false,
  });
}
