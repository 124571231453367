import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { STATUS_INVITATION } from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class ApiCallService {
  private APIPATH: string = '/event-day';
  private URLAPI: string = `${environment.url}${this.APIPATH}`;
  private URLAPI_invitation: string = `${environment.url}/images`;
  private URLAPI_update: string = `${environment.url}/invitations`;

  constructor(private http: HttpClient) {}

  getMyEvents(code?: string): Observable<any> {
    return this.http.get<any>(`${this.URLAPI}/invitation/${code}`);
  }

  downloadInvitation(code: string): Observable<any> {
    return this.http.get(`${this.URLAPI_invitation}/${code}`, {
      responseType: 'blob',
    });
  }

  updateInvitation(code: string, status: STATUS_INVITATION): Observable<any> {
    return this.http.put(
      `${this.URLAPI_update}/code/${code}/${status}`,
      {}
    );
  }
}
