import { Component } from '@angular/core';
import { BehaviorSubject, debounceTime, pipe, switchMap, take } from 'rxjs';
import { ApiCallService } from './services/api-call.service';
import * as FileSaver from 'file-saver';
import { countDown, animationjs } from '../assets/js/count-down.js';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface IEvent {
  access?: string;
  confirmed?: number;
  invitation_code?: string;
  lastname?: string;
  name?: string;
  second_lastname?: string;
  table?: string;
}

export enum STATUS_INVITATION {
  PENDING = 0,
  CONFIRMED = 1,
  CANCELED = 2,
  REQUEST = 3,
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  validInvitation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  invitationCode: string = '';
  title = 'myweddingkaren';
  eventDay: IEvent = {};
  eventDayValidate: IEvent = {};
  isMobile: boolean = false;
  private config: { version: '1.2.0' };

  constructor(
    private service: ApiCallService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    animationjs();
    countDown();
    this.route.queryParams
      .pipe(
        debounceTime(700),
        switchMap((params) => {
          if (!!params['invitation']) {
            return this.service.getMyEvents(params['invitation']);
          } else {
            return [];
          }
        })
      )
      .subscribe((resp) => {
        if (resp.length > 0) {
          this.eventDayValidate = resp[0];
          this.validInvitation.next(true);
          this.showModal.next(true);
        } else {
          this.validInvitation.next(false);
          this.showModal.next(true);
        }
      });
  }
  sendRequestInvitation() {
    this.service.getMyEvents(this.invitationCode).subscribe((resp) => {
      if (resp.length > 0) {
        this.showForm.next(false);
        this.invitationCode = '';
        this.showRequest.next(true);
        this.eventDay = resp[0];
        this.showError.next(false);
      } else {
        this.showError.next(true);
      }
    });
  }

  downloadImage(): void {
    this.service
      .downloadInvitation(this.eventDay.invitation_code)
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'image/jpeg' }); // Ajusta el tipo de archivo según tu caso
        FileSaver.saveAs(blob, 'invitation.jpg'); // Utiliza file-saver para descargar la imagen
        window.open(
          'https://www.organizaeventos-dev.cloud/api/images/' +
            this.eventDay.invitation_code,
          '_blank'
        );
      });
  }

  invitationAccept() {
    this.service
      .updateInvitation(
        this.eventDay.invitation_code,
        STATUS_INVITATION.CONFIRMED
      )
      .subscribe((resp) => {
        this.eventDay.confirmed = STATUS_INVITATION.CONFIRMED;
        this.showRequest.next(true);
      });
  }

  // reject invitation
  invitationReject() {
    this.service
      .updateInvitation(
        this.eventDay.invitation_code,
        STATUS_INVITATION.CANCELED
      )
      .subscribe((resp) => {
        this.eventDay.confirmed = STATUS_INVITATION.CANCELED;
        this.showRequest.next(true);
      });
  }
}
